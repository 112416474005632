
import { Vue, Options } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import {
  ReadyToCargo,
  ReadyToCargoListEntities,
  RequestReadyToCargoList
} from "@/domain/entities/ReadyToCargo";
import { Pagination } from "@/domain/entities/Pagination";
import { CargoController } from "@/app/ui/controllers/CargoController";

@Options({
  emits: ["close"]
})
export default class ReadyToCargoSection extends Vue {
  async mounted() {
    await this.fetchReadyToCargoList();
    if (!this.readyToCargoList.data.length) {
      this.$emit("close");
    }
    this.keyReadyToCargo++;
  }

  isListOnLoading = false;

  get loadingCard() {
    return this.isListOnLoading ? 4 : 0;
  }

  keyReadyToCargo = 0;
  readyToCargoList = new ReadyToCargoListEntities({
    data: [],
    pagination: new Pagination(0, 10, 10)
  });
  get dataReadyToCargoIsFinished() {
    const offsetTotalData = this.readyToCargoList.pagination.totalData || 0;
    const limitPerPage = this.readyToCargoList.pagination.limit;
    return offsetTotalData < limitPerPage;
  }

  async fetchReadyToCargoList() {
    if (this.dataReadyToCargoIsFinished) return;
    try {
      this.isListOnLoading = true;
      this.readyToCargoList.pagination.page++;
      const resp: ReadyToCargoListEntities = await ReadyToCargoController.getReadyToCargoList(
        new RequestReadyToCargoList({
          page: this.readyToCargoList.pagination.page,
          limit: this.readyToCargoList.pagination.limit,
          status: "Sudah Siap",
          sortBy: "rtc_updated_at"
        })
      );
      this.readyToCargoList.data = [
        ...this.readyToCargoList.data,
        ...resp.data
      ];
      this.readyToCargoList.pagination = resp.pagination;
    } catch (error) {
      this.readyToCargoList.pagination.page--;
    } finally {
      this.isListOnLoading = false;
    }
  }

  confirmationProcessReadyToCargo(readyToCargo: ReadyToCargo) {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: `Apakah anda ingin Memproses<br />${readyToCargo.noReadyToCargo}`,
        image: "are-you-sure",
        message:
          "Pastikan anda sudah mengumpulkan semua bagging di 1 tempat yang sama",
        textSuccess: "Proses",
        textCancel: "Kembali",
        onClose: () => MainAppController.closeMessageModal(),
        onSubmit: () => {
          MainAppController.closeMessageModal();
          this.processReadyToCargo(readyToCargo);
        }
      })
    );
  }

  autoFillDestination(detailSTT: any) {
    CargoController.autoFillDestination(detailSTT);
  }

  async processReadyToCargo(readyToCargo: ReadyToCargo) {
    await ReadyToCargoController.getDetailReadyToCargo(readyToCargo.id);
    CargoController.mappingReadyToCargo();
    this.autoFillDestination(CargoController.sourceSttFromLocalStorage[0]);
    this.$emit("close");
  }
}
