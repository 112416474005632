
import { Options, Vue } from "vue-class-component";
import { CargoController } from "@/app/ui/controllers/CargoController";
import ListCargoType from "@/app/ui/views/cargo/booking-cargo/second-step/list-cargo-type.vue";
import CargoTypeCard from "@/app/ui/views/cargo/booking-cargo/components/cargo-type-card.vue";
import { getTokenNGen } from "@/app/infrastructures/misc/Cookies";
import { NGenController } from "@/app/ui/controllers/NGenController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { OptionsClass } from "@/domain/entities/MainApp";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { firstCapitalize, storeDatatoLocalStorage } from "@/app/infrastructures/misc/Utils";
import { AirportData } from "@/domain/entities/Cargo";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";

@Options({
  emits: "closeModal",
  components: { ListCargoType, CargoTypeCard }
})
export default class ModalCargoType extends Vue {
  async mounted() {
    this.searchCargo();
    CargoController.setShowSelectAirport(false);
    CargoController.setShowSelectAirportToLocalStorage(false);
  }

  showInfoDividerCargo = true;

  unmounted() {
    CargoController.setShowModalCargoType(false);
  }

  cargoType = "";
  cargoTypeStatus = "";
  isDisableButton = true;

  get showModalCargoType() {
    return CargoController.showModalCargoType;
  }

  get readyToCargoDetail() {
    return CargoController.readyToCargoDetail;
  }

  get formCargo() {
    return CargoController.form;
  }

  get titleButtonDialog() {
    return this.isEditReadyToCargo
      ? "Ubah Transportasi"
      : "Simpan Transportasi";
  }

  get titleDialog() {
    return this.isEditReadyToCargo ? "Ubah Kargo" : "Pilih Kargo";
  }

  get isEditReadyToCargo() {
    return CargoController.isEditReadyToCargo;
  }

  searchCargo() {
    CargoController.getCargoOptions({
      origin: {
        code:
          this.readyToCargoDetail.originCode ||
          AccountController.accountData.account_location.city_code ||
          "",
        name:
          this.readyToCargoDetail.originName ||
          AccountController.accountData.account_location.city_name ||
          ""
      },
      destination: {
        code:
          this.readyToCargoDetail.destinationCode ||
          this.formCargo.destinationCityCode ||
          "",
        name:
          this.readyToCargoDetail.destinationName ||
          this.formCargo.destinationCityName ||
          ""
      },
      isPlaneManual: true
    });
  }
  cancelModalCargoType() {
    CargoController.setShowModalCargoType(false);
    CargoController.setShowSelectAirport(false);
    CargoController.setShowSelectAirportToLocalStorage(false);
  }

  // airport
  airportMappingData(data: AirportData[]) {
    return data.map(airport => ({
      ...airport,
      name: `${airport.airportCode} - ${airport.airportName}`,
      value: airport.airportCode
    }));
  }
  get optionAirportDestination() {
    return this.airportMappingData(CargoController.airportDataDestination.data);
  }

  selectCargoType(type: string, status: string) {
    this.cargoType = type;
    this.cargoTypeStatus = status;
    this.isDisableButton = false;
    this.initPlaneAuto();
  }

  get isPlaneAuto() {
    return this.cargoType === "plane" && this.cargoTypeStatus === "auto";
  }
  get isPlaneManual() {
    return this.cargoType === "plane" && this.cargoTypeStatus === "manual";
  }
  async onCheckAuthNGen(): Promise<void> {
    let isAuth = false;
    if (!getTokenNGen()) {
      isAuth = await NGenController.onLoginNGen();
    } else {
      isAuth = true;
    }

    if (this.isPlaneAuto && isAuth) {
      CargoController.setAirportsPopupModel(
        JSON.parse(JSON.stringify(CargoController.form.airports))
      );
      CargoController.setShowSelectAirport(true);
      CargoController.setShowSelectAirportToLocalStorage(true)
    }
  }

  async initPlaneAuto(): Promise<void> {
    await this.fetchAirportOrigin(this.originCity.code || "");
    await this.fetchAirportDestination(this.form.destinationCityCode);
  }
  get form() {
    return CargoController.form;
  }
  fetchAirportOrigin(search: string, hideDefault = true) {
    search = search || "";
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    return CargoController.getAirportListOrigin({
      airportCode: "",
      cityCode: search,
      page: 1,
      limit: 10,
      isLocation: false,
      hideDefault: hideDefault
    });
  }
  fetchAirportDestination(search: string) {
    search = search || "";
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    return CargoController.getAirportListDestination({
      airportCode: "",
      cityCode: search,
      page: 1,
      limit: 10
    });
  }

  // origin city
  get originCity(): OptionsClass {
    const detailDistrict = LocationController.districtDetail;
    return new OptionsClass({
      name: `${detailDistrict.cityCode} - ${firstCapitalize(
        detailDistrict.cityName.toLowerCase()
      )}`,
      code: detailDistrict.cityCode
    });
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  get totalPcs() {
   return this.form.actualTotalSTTPieces;
  }

  async changeCargoType() {
    if (this.cargoType) {
      CargoController.setOnChangeVehicleNumber("");
      if(this.flowBooking.includes("rtc") && this.isPlaneAuto) {
        storeDatatoLocalStorage('backup-flight-rtc', this.form.flightsData);
      } else {
        CargoController.resetFlightData();
      }
      CargoController.form.cargoType = this.cargoType;
      CargoController.form.cargoTypeStatus = this.cargoTypeStatus;
      CargoController.setIsChangeCargoType(true);
      if (this.isPlaneManual) {
        CargoController.setFillForm(false);
      }
      if (this.isPlaneAuto) {
        this.onCheckAuthNGen();
        CargoController.setShowSelectAirportToLocalStorage(true);
        CargoController.setProgressValue(0);   
      } else if (!this.isEditReadyToCargo && (this.flowBooking === "manual" || this.flowBooking  === 'rebooking' && !this.isFromRebookingRTC)) {
        CargoController.setProgressValue(2);
      }
    }
    if (!this.isPlaneAuto || getTokenNGen()) {
      CargoController.setShowModalCargoType(false);
    }
    const cargoType = {
      type: this.cargoType,
      status: this.cargoTypeStatus
    };
    CargoController.setCargoType(cargoType);
    if(!this.isChangeVolume) CargoController.calculationVolumeWeight(this.totalPcs);
  }

  get cargoInputForm() {
    return CargoController.cargoInputForm;
  }

  get isFromRebookingRTC() {
    return CargoController.isRebookingFromRTC;
  }

  get isChangeVolume() {
    return CargoController.isChangeVolumeActual;
  }
}
